import { maintenance_outage_order_maker, field_maker } from "helper";
import { alias_maker, select_options_maker } from "helper";

export const MAINTENANCE_FIELD_ENUM = {
    maintenance_starttime: "maintenance_starttime",
    maintenance_endtime: "maintenance_endtime",
    comments: "comments",
    url_affected: "url_affected",
};

const maintenance_fields = {
    ...field_maker("id", "-", "string"),
    ...field_maker(MAINTENANCE_FIELD_ENUM.maintenance_starttime, "Start Time (UTC)", "dateTime"),
    ...field_maker(MAINTENANCE_FIELD_ENUM.maintenance_endtime, "End Time (UTC)", "dateTime"),
    ...field_maker(MAINTENANCE_FIELD_ENUM.comments, "Comments", "string"),
    ...field_maker(MAINTENANCE_FIELD_ENUM.url_affected, "URLs Affected", "string"),
    ...field_maker("__mark__", "Mark for Delete", "string"),
};

const MAINTENANCE_ORDER = [
    maintenance_outage_order_maker(
        maintenance_fields[MAINTENANCE_FIELD_ENUM.maintenance_starttime],
        200
    ),
    maintenance_outage_order_maker(
        maintenance_fields[MAINTENANCE_FIELD_ENUM.maintenance_endtime],
        200
    ),
    maintenance_outage_order_maker(maintenance_fields[MAINTENANCE_FIELD_ENUM.comments], 400),
    maintenance_outage_order_maker(maintenance_fields[MAINTENANCE_FIELD_ENUM.url_affected], 400),
];

export const NCE_URLS = [
    alias_maker("https://api.1nce.com/management-api/v1/products", "1NCE-API-Products"),
    alias_maker("https://api.1nce.com/management-api/v1/sims", "1NCE-API-Sims"),
    alias_maker("https://portal.1nce.com/portal/customer/connectivitysuite", "1NCE-OS"),
    alias_maker("https://portal.1nce.com", "1NCE-Portal-Content"),
    alias_maker("https://portal.1nce.com/portal/customer/login", "1NCE-Portal-Login"),
    alias_maker("https://shop.1nce.com/de", "1NCE-Shop-Content-DE"),
    alias_maker("https://shop.1nce.com/portal/shop", "1NCE-Shop-Content-old"),
    alias_maker("https://shop.1nce.com/en", "1NCE-Shop-Content"),
    alias_maker("https://1nce.com", "1NCE-Website-Content"),
    alias_maker("https://wyj52gwde9.execute-api.eu-central-1.amazonaws.com/webprobes/list-clients", "BSS-AAX-API"),
];

// export const DTAG_TDG_URLS = [
//     alias_maker(
//         "https://api-business-portal-smart-connect.telekom.de/management-api/v1/sims",
//         "API - Swagger SIMs"
//     ),
//     alias_maker(
//         "https://api-business-portal-smart-connect.telekom.de/management-api/v1/products",
//         "API - Swagger Products"
//     ),
//     alias_maker(
//         "https://business-portal-smart-connect.telekom.de/tdg-portal/login",
//         "Customer portal (Login)"
//     ),
//     alias_maker("https://smart-connect-shop.iot.telekom.com", "Customer shop"),
//     alias_maker("https://business-portal-smart-connect.telekom.de", "Customer portal (Load)"),
// ];

export const DTAG_TMNL_URLS = [
    alias_maker(
        "https://api-easyconnect.iot.t-mobile.nl/management-api/v1/products",
        "TMNL-API-Products"
    ),
    alias_maker(
        "https://api-easyconnect.iot.t-mobile.nl/management-api/v1/sims",
        "TMNL-API-Sims"
    ),
    alias_maker("https://easyconnect.iot.t-mobile.nl/tmnl-portal/login", "TMNL-Portal-Login"),
];

// export const DT_IOT_URLS = [];

export const DTIOT_WEB_PROBE_URLS = [
    alias_maker("https://concloud.iot.telekom.com", "Service Portal Availability"),
    alias_maker("https://api.concloud.iot.telekom.com/api/v1/endpoint?page=1&per_page=20", "API Availability"),
];

export const PROBE_SERVICES = {
    "1NCE": NCE_URLS,
    // "DTAG/TDG": DTAG_TDG_URLS,
    "DTAG/TMNL": DTAG_TMNL_URLS,
    // "NGP DT-IoT": DT_IOT_URLS,
};

export const DTIOT_WEB_PROBE_SERVICES = {
    "DT-IoT": DTIOT_WEB_PROBE_URLS,
};

export const NETWORK_PROBE_SERVICES = [
    alias_maker("GPRS (2G/3G)"),
    alias_maker("LTE/NB-IoT (4G)"),
    alias_maker("SMS-MO"),
    alias_maker("SMS-MT"),
    alias_maker("Open VPN"),
];

export const NGP_NETWORK_PROBE_SERVICES = [
    alias_maker("2G"),
    alias_maker("4G"),
    alias_maker("NB-IoT"),
    alias_maker("SMS-MO"),
    alias_maker("SMS-MT"),
];

export const PROBE_SERVICES_OPTIONS = select_options_maker(Object.keys(PROBE_SERVICES));
export const DTIOT_WEB_PROBE_SERVICES_OPTIONS = select_options_maker(Object.keys(DTIOT_WEB_PROBE_SERVICES));


export const PROBE_OPTIONS = [
    alias_maker("webprobe_kpi_mnt_conf", "Web"),
    alias_maker("network_probes_1nce_mnt_conf", "1NCE Network"),
    alias_maker("dt_iot_network_probes", "DT-IoT Network"),
    alias_maker("dt_iot_web_probes", "DT-IoT Web"),
    // alias_maker("network_probes_tmnl_mnt_conf", "TMNL Network"),
    // alias_maker("network_probes_dtag_mnt_conf", "DTAG Network"),
];

export default MAINTENANCE_ORDER;

import React, { useState } from "react";

import { toast } from 'react-toastify';
import { useRecoilState } from "recoil";
import Container from "@mui/material/Container";

import { maintenance_form_state, maintenance_multiple_form_state, user_state } from "components/root";
import SimplePageTitle from "components/atoms/simple_page_title";
import MaintenanceForm from "components/organisms/maintenance_form";
import postMaintenance from "api/maintenance";
import PropTypes from 'prop-types'

import {
  MAINTENANCE_FIELD_ENUM,
  NGP_NETWORK_PROBE_SERVICES,
  NETWORK_PROBE_SERVICES,
  PROBE_SERVICES,
  PROBE_SERVICES_OPTIONS,
  DTIOT_WEB_PROBE_SERVICES_OPTIONS,
  DTIOT_WEB_PROBE_SERVICES
} from "constant_variables/maintenance"

import SubmitButton from "components/atoms/submit_button"
import MaintenanceOverviewTable from "components/organisms/overview_table";
import { get_duration_value } from 'helper';

const MaintenanceMultiple = ({ outage = false }) => {
  const [form, setForm] = useRecoilState(maintenance_form_state);
  const [userState] = useRecoilState(user_state);
  const [maintenances, setMaintenances] = useRecoilState(maintenance_multiple_form_state);
  const [loading, setLoading] = useState(false);
  const disable_url = !["webprobe_kpi_mnt_conf", "dt_iot_web_probes"].includes(form["probe"]);
  let service_options;
  let urls;

  // Moved to switch statement
  // const service_options = disable_url ? (form["probe"] === "dt_iot_network_probes" ? NGP_NETWORK_PROBE_SERVICES : NETWORK_PROBE_SERVICES) : PROBE_SERVICES_OPTIONS;

  switch (form["probe"]) {
    case "webprobe_kpi_mnt_conf":
      service_options = PROBE_SERVICES_OPTIONS;
      urls = PROBE_SERVICES[form["service"]]
      break;
    case "network_probes_1nce_mnt_conf":
      service_options = NETWORK_PROBE_SERVICES;
      break;
    case "dt_iot_network_probes":
      service_options = NGP_NETWORK_PROBE_SERVICES;
      break;
    case "dt_iot_web_probes":
      service_options = DTIOT_WEB_PROBE_SERVICES_OPTIONS;
      urls = DTIOT_WEB_PROBE_SERVICES[form["service"]]
      break;
    default:
      service_options = PROBE_SERVICES_OPTIONS;
      break;
  }

  let read_only = true;
  if (userState !== null && userState.permissions) {
    read_only = userState.permissions.read_only;
  }

  const handle_form_change = (key, value) => {
    if (key === "probe") {
      setForm((old) => ({
        ...old,
        url_affected: [],
      }))
      setForm((old) => ({
        ...old,
        test_names: [],
      }))
    }

    if (key === "url_affected") {
      const services = value.map((url) => urls.find(({ key }) => key === url).alias);
      setForm((old) => ({
        ...old,
        test_names: services,
      }))
    }

    setForm((old) => ({
      ...old,
      [key]: value,
    }));
  };

  const handle_all_change = () => {
    const urls_values = urls.map((u) => u.key);
    setForm((old) => ({
      ...old,
      [MAINTENANCE_FIELD_ENUM.url_affected]: urls_values,
    }));
  };


  const clear_form = () => {
    setForm(() => ({
      maintenance_starttime: new Date(),
      maintenance_endtime: new Date(),
      comments: "",
      url_affected: [],
      service: "",
      probe: "",
      outage,
      duration: 0,
      test_names: []
    }))
  }

  const row_value_maker = (form) => {
    return {
      id: `${form.maintenance_starttime.toString()}-${form.probe}`,
      duration: get_duration_value(form[MAINTENANCE_FIELD_ENUM.maintenance_starttime], form[MAINTENANCE_FIELD_ENUM.maintenance_endtime])
    }
  }


  const onAdd = () => {
    const duration = get_duration_value(form[MAINTENANCE_FIELD_ENUM.maintenance_starttime], form[MAINTENANCE_FIELD_ENUM.maintenance_endtime]);
    let allow_add = true;
    if (duration > 86399) {
      allow_add = confirm("Maintenance lasts more that one day! Press confirm to add!");
    }
    if (allow_add) {
      setMaintenances((old) => ([
        ...old,
        { ...form, ...row_value_maker(form) }
      ]));
      setForm((old) => ({
        ...old,
        url_affected: [],
      }))
    }
  }
  console.log(maintenances)
  const onDelete = (index) => {
    const newMaintenances = maintenances.filter((m, i) => i !== index);
    setMaintenances(newMaintenances);
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    let error = false;
    try {
      await postMaintenance(maintenances);
    } catch (err) {
      console.trace(err);
      error = true;
    } finally {
      setLoading(false);
      setMaintenances([]);
      if (error) toast.error("Failed");
      else toast.success("Success");

      if (!error) setForm(() => ({
        maintenance_starttime: new Date(),
        maintenance_endtime: new Date(),
        comments: "",
        url_affected: [],
        service: "",
        probe: "",
        outage,
      }))
    }
  };

  return (
    <Container maxWidth="false" sx={{ maxWidth: "1920px" }}>
      <SimplePageTitle title={`Add ${outage ? "Outage" : "Maintenance"} Entries`} />
      <MaintenanceForm
        form={form}
        loading={loading}
        submit={onAdd}
        handleChange={handle_form_change}
        handleAllChange={handle_all_change}
        service_options={service_options}
        urls={urls}
        disable_url={disable_url}
        disabled={read_only}
        clearForm={clear_form}
      />
      {maintenances && maintenances.length > 0 && <>
        <MaintenanceOverviewTable loading={loading} maintenances={maintenances} handleDelete={onDelete} />
        <SubmitButton label="Submit maintenances" onClick={onSubmit} disabled={read_only || loading || maintenances.length === 0} />
      </>}
    </Container>
  );
};

MaintenanceMultiple.propTypes = {
  outage: PropTypes.bool,
}

export default MaintenanceMultiple;

import { trace_field_enum } from "constant_variables/trace_fields";
import { atom } from "recoil";

export const logbook_form_state = atom({
  key: "logbook_form",
  default: {
    PK: "MainLog",
    "start-time-cetcest": new Date(),
    "end-time-cetcest": new Date(),
    "dur-hhmm": 0,
    area: "",
    "entry-type": "",
    tenant: "",
    creator: "",
    "on-call": "",
    where: "",
    "customer-impact": "",
    "detected-by": "",
    "ticket-change-id": "",
    what: "",
    "root-cause-comments": "",
  },
});

export const nce_os_form_state = atom({
  key: "nce_os_form",
  default: {
    "PK": "NceOSLog",
    "start-time-cetcest": new Date(),
    "end-time-cetcest": new Date(),
    "entry_type": "",
    "trigger": "",
    "area": "",
    "area_other": "",
    "area_final": "",
    "on_call": "",
    "customer_impact": "",
    "runbook_needed": "",
    "runbook_url": "",
    "root_cause_comments": "",
    "details": "",
    "solve_closing_comments": "",
  },
});

export const trace_form_state = atom({
  key: "trace_form",
  default: {
    [trace_field_enum.start_time]: new Date(),
    [trace_field_enum.end_time]: new Date(),
    [trace_field_enum.protocol]: "",
    [trace_field_enum.export_type]: [],
    [trace_field_enum.imsi]: "",
    status: "QUEUED",
  },
});

export const maintenance_form_state = atom({
  key: "maintenance_form",
  default: {
    maintenance_starttime: new Date(),
    maintenance_endtime: new Date(),
    comments: "",
    url_affected: [],
    service: "1NCE",
    probe: "",
    outage: false,
    duration: 0,
    test_names: []
  },
});

export const tisp_maintenance_form_state = atom({
  key: "tisp_maintenance_form",
  default: {
    maintenance_starttime: new Date(),
    maintenance_endtime: new Date(),
    comments: "",
    url_affected: [],
    service: "",
    probe: "tisp_web_probes",
    outage: false,
    duration: 0,
  },
});

export const marketing_form_state = atom({
  key: "marketing_form",
  default: {
    PK: "MarketingLog",
    "start-time-cetcest": new Date(),
    "activity-name": "",
    area: "",
    "sub-area": "",
    "main-region": "",
    "main-vertical": "",
    "main-topic": "",
    "main-goal": "",
  },
});

export const api_probes_form_state = atom({
  key: "api_probes_form",
  default: {
    country: "",
    mandant: "",
    app_client: "",
  },
});

// export const forecast_form_state = atom({
//   key: "forecast_form",
//   default: {
//     PK: "ForecastLog",
//     account_owner: "",
//     month: 1,
//     region: "",
//     date_key: new Date(),
//     sales_fc: 0,
//     rev_fc: 0,
//     rev_per_sim_fc: 0,
//     rev_per_gig_fc: 0,
//   },
// })

export const forecast_form_state = atom({
  key: "forecast_form",
  default: {
    PK: "NewForecastLog",
    name: "",
    team: "",
    role: "",
    goal_type: "",
    target_type: "",
    year: 0,
    month: 1,
    value: 0,
  },
});

export const on_call_form_state = atom({
  key: "on_call_form",
  default: {
    PK: "OnCallLog",
    email: "",
    status: "No issues",
    comment: "",
    log_time: new Date().toUTCString(),
  },
});

export const admin_form_state = atom({
  key: "admin_form",
  default: [],
});

export const maintenance_multiple_form_state = atom({
  key: "maintenance_multiple_forms",
  default: [],
});

export const tisp_maintenance_multiple_form_state = atom({
  key: "tisp_maintenance_multiple_forms",
  default: [],
});

export const trace_multiple_form_state = atom({
  key: "trace_multiple_forms",
  default: [],
});

export const logbook_table_state = atom({
  key: "logbook_table_state",
  default: [],
});

export const nce_changes_table_state = atom({
  key: "nce_changes_table_state",
  default: [],
});

export const forecast_table_state = atom({
  key: "forecast_table_state",
  default: [],
});

export const marketing_table_state = atom({
  key: "marketing_table_state",
  default: [],
});

export const maintenance_table_state = atom({
  key: "maintenance_table_state",
  default: [],
});

export const admin_table_state = atom({
  key: "admin_table_state",
  default: [],
});

export const on_call_table_state = atom({
  key: "on_call_table_state",
  default: [],
});

export const user_state = atom({
  key: "user_state",
  default: null,
});

export const version_state = atom({
  key: "version_state",
  default: "",
});

export const customer_feedback_url = atom({
  key: "customer_feedback_url",
  default: "",
});
